<template>
  <div>
    <v-card class="mb-3">
      <v-card-title>Transfer Bank</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="rules.valid" :disabled="isLoading">
          <v-dialog
            ref="dialog"
            v-model="form.date.modal"
            :return-value.sync="form.date.value"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date.value"
                :rules="rules.date"
                class="mb-3"
                label="Tanggal"
                prepend-inner-icon="mdi-calendar"
                hide-details="auto"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="form.date.value"
              color="primary"
              locale="id"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="form.date.modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.date.value)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-text-field
            v-model="form.reference"
            :rules="rules.reference"
            class="mb-3"
            label="Referensi"
            hide-details="auto"
            outlined
          ></v-text-field>

          <v-select
            v-model="form.source_bank_id"
            :rules="rules.source_bank_id"
            :items="banks"
            class="mb-3"
            label="Dari Kas/Bank"
            hide-details="auto"
            item-text="bank_account_name"
            item-value="id"
            outlined
          ></v-select>

          <template v-if="form.source_bank_id && bankBalance !== null">
            <div class="px-3">
              <div>Saldo Kas/Bank</div>
              <h3 class="mb-3">{{ currency(bankBalance) }}</h3>
            </div>
          </template>

          <v-select
            v-model="form.target_bank_id"
            :rules="rules.target_bank_id"
            :items="banks"
            class="mb-3"
            label="Ke Kas/Bank"
            hide-details="auto"
            item-text="bank_account_name"
            item-value="id"
            outlined
          ></v-select>

          <v-text-field
            v-model="form.amount"
            :rules="rules.amount"
            class="mb-3"
            label="Nilai"
            hide-details="auto"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.charge"
            :rules="rules.charge"
            class="mb-3"
            label="Biaya"
            hide-details="auto"
            outlined
          ></v-text-field>

          <v-textarea
            v-model="form.memo"
            class="mb-3"
            label="Catatan"
            hide-details="auto"
            rows="1"
            auto-grow
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
    </v-card>
    <v-btn
      :loading="isLoading"
      class="mt-3"
      color="primary"
      block
      x-large
      @click="submit"
    >
      Proses
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    form: {
      date: {
        modal: false,
        value: new Date().toISOString().substring(0, 10),
      },
      reference: null,
      source_bank_id: null,
      target_bank_id: null,
      amount: null,
      charge: null,
      memo: null,
    },
    rules: {
      valid: true,
      date: [(v) => !!v || 'Tanggal harus dipilih'],
      reference: [(v) => !!v || 'Referensi harus diisi'],
      source_bank_id: [(v) => !!v || 'Bank harus dipilih'],
      target_bank_id: [(v) => !!v || 'Bank harus dipilih'],
      amount: [(v) => !!v || 'Nilai harus diisi'],
    },
    banks: [],
    bankBalance: null,
  }),

  computed: {
    userId() {
      return this.$cookies.get('session').id
    },

    transNo() {
      return this.$route.params.transNo
    },
  },

  watch: {
    'form.source_bank_id'() {
      this.getBankBalance()
    },
  },

  async mounted() {
    this.isLoading = true

    await this.getNextReference()
    await this.getBanks()
    await this.getBankTrans()

    this.isLoading = false
  },

  methods: {
    async getBankTrans() {
      await this.$http
        .get('/accounting/bank-transfers/' + this.transNo)
        .then(({ data }) => {
          this.form.date.value = data.trans_date
          this.form.reference = data.ref
          this.form.source_bank_id = data.source_bank_id
          this.form.target_bank_id = data.target_bank_id
          this.form.amount = -data.amount - data.charge
          this.form.charge = data.charge
          this.form.memo = data.memo
        })
    },

    async getNextReference() {
      await this.$http.get('/references/next/4').then((response) => {
        this.form.reference = response.data
      })
    },

    async getBanks() {
      await this.$http.get('/banks').then((response) => {
        this.banks = response.data
      })
    },

    async getBankBalance() {
      if (this.form.source_bank_id) {
        await this.$http
          .get('/banks/' + this.form.source_bank_id + '/balance')
          .then((response) => {
            this.bankBalance = response.data
          })
      } else {
        this.bankBalance = null
      }
    },

    currency(value) {
      return Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },

    async submit() {
      this.$refs.form.validate()

      if (!this.rules.valid) {
        return
      }

      this.isLoading = true

      const data = {
        ...this.form,
        date: this.form.date.value,
        userId: this.userId,
      }

      await this.$http
        .put('/accounting/bank-transfers/' + this.transNo, data)
        .then(() => {
          this.$router.push('/accounting/banks/journals')
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.hide-thead table thead {
  display: none !important;
}
</style>
